<template>
  <b-card>

    <validation-observer ref="simpleRules">
      <b-form>
        <!-- Section Informations -->
        <h4>{{ $t('Informations') }}</h4>
        <span class="font-weight-bold">{{ $t('The fields with (*) are required') }}</span>
        <span class="font-weight-bold"
              style="display: block;">** {{ $t('Chassis and or plate must be inserted') }}</span>
        <hr class="mb-2">
        <b-row>
          <!-- Field: Customer -->
          <b-col cols="12" md="4" v-if="!isFinalConsumer">
            <b-form-group :label="$t('Owner')" label-for="customer">
              <vue-autosuggest :disabled="isFinalConsumer" v-model="searchQuery" :suggestions="filteredOptions"
                               :input-props="inputProps" @selected="selectHandler" @input="onInputChange">
                <template slot-scope="{suggestion}">
                  <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
                </template>
              </vue-autosuggest>
            </b-form-group>
          </b-col>

          <!-- Field: Manufacturer -->
          <b-col cols="12" md="4">
            <b-form-group :label="$t('Manufacturer')+'*'" label-for="manufacturer">
              <validation-provider #default="{ errors }" name="manufacturer" rules="required">
                <v-select :disabled="isFinalConsumer" @input="setSelected" :state="errors.length > 0 ? false:null"
                          v-model="assetData.manufacturerId" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="manufacturerOptions" :reduce="val => val.value" :clearable="false"
                          input-id="manufacturer"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Model -->
          <b-col cols="12" md="4">
            <b-form-group :label="$t('Model')+'*'" label-for="model">
              <validation-provider #default="{ errors }" name="model" rules="required">
                <v-select :disabled="isFinalConsumer" :state="errors.length > 0 ? false:null"
                          v-model="assetData.modelId" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="modelOptions" :reduce="val => val.value" :clearable="false" input-id="model"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Model Name -->
          <b-col cols="12" md="4">
            <b-form-group :label="$t('Model Name')" label-for="model-name">
              <validation-provider #default="{ errors }" name="model-name" rules="max:50">
                <b-form-input :disabled="isFinalConsumer" id="model-name" v-model="assetData.modelName"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Asset Type -->
          <b-col cols="12" md="4">
            <b-form-group :label="$t('Asset Type')+'*'" label-for="asset-type">
              <validation-provider #default="{ errors }" name="asset-type" rules="required">
                <v-select :disabled="isFinalConsumer" :state="errors.length > 0 ? false:null"
                          v-model="assetData.assetType" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="assetsOptions" :reduce="val => val.value" :clearable="false" input-id="asset-type"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Color -->
          <b-col cols="12" md="4">
            <b-form-group :label="$t('Color')" label-for="color">
              <validation-provider #default="{ errors }" name="color" rules="max:30">
                <b-form-input id="color" v-model="assetData.color"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Manufacturing Year -->
          <b-col cols="12" md="4">
            <b-form-group :label="$t('Manufacturing Year')" label-for="manufacturing-year">
              <validation-provider #default="{ errors }" name="manufacturing-year" rules="max:4">
                <b-form-input id="color" v-model="assetData.manufacturingYear" type="number"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Model Year -->
          <b-col cols="12" md="4">
            <b-form-group :label="$t('Model Year')" label-for="model-year">
              <validation-provider #default="{ errors }" name="model-year" rules="max:4">
                <b-form-input id="color" v-model="assetData.modelYear" type="number"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Plate -->
          <b-col cols="12" md="4">
            <b-form-group :label="$t('Plate')+'**'" label-for="plate">
              <validation-provider #default="{ errors }" name="plate" :rules="rulesPlate()">
                <b-form-input id="plate" v-model="assetData.plate"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Nickname -->
          <b-col cols="12" md="4">
            <b-form-group :label="$t('Nickname')" label-for="nickname">
              <validation-provider #default="{ errors }" name="nickname" rules="max:20">
                <b-form-input id="nickname" v-model="assetData.nickname"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Chassis -->
          <b-col cols="12" md="4">
            <b-form-group :label="$t('Chassis')+'**'" label-for="chassis">
              <validation-provider #default="{ errors }" name="chassisNumber" :rules="rulesChassis()">
                <b-form-input id="chassis" v-model="assetData.chassisNumber"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: invoice -->
          <b-col cols="12" md="4">
            <b-form-group :label="$t('Invoice')" label-for="invoice">
              <validation-provider #default="{ errors }" name="invoice" rules="max:50">
                <b-form-input id="invoice" v-model="assetData.invoice"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Cost Center -->
          <b-col cols="12" md="4">
            <b-form-group :label="$t('Cost Center')" label-for="cost-center">
              <validation-provider #default="{ errors }" name="cost-center" rules="max:100">
                <b-form-input id="cost-center" v-model="assetData.costCenter"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Notes -->
          <b-col cols="12" md="12">
            <b-form-group :label="$t('Note')" label-for="note">
              <validation-provider #default="{ errors }" name="note" rules="max:255">
                <b-form-textarea v-model="assetData.note" id="note" rows="5"/>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Btn: Save SUbmit -->
          <b-col v-if="!user.role.endsWith('VI')" cols="12">
            <b-button variant="primary" type="submit" @click.prevent="validationForm">
              {{ $t('Save') }}
            </b-button>
          </b-col>

        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BTab,
  BTabs,
  BCard,
  BAlert,
  BLink,
} from 'bootstrap-vue'
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  BFormTextarea,
} from 'bootstrap-vue'
import {
  ref,
  onUnmounted
} from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import assetStoreModule from '../assetStoreModule'

import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.br'

import vSelect from 'vue-select'
import {
  VueAutosuggest
} from 'vue-autosuggest'

import {
  ValidationProvider,
  ValidationObserver
} from 'vee-validate'
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
  max
} from '@validations'


export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCardText,
    BFormTextarea,

    Cleave,
    vSelect,
    VueAutosuggest

  },
  data() {
    return {
      myId: router.currentRoute.params.id,
      required,
      email,
      confirmed,
      url,
      between,
      alpha,
      integer,
      password,
      min,
      digits,
      alphaDash,
      length,
      max,

      //searchQuery: '',

      datasuggest: [],
      filteredOptions: [],
      inputProps: {
        id: 'autosuggest__input',
        class: 'form-control',
        placeholder: "Search for your customer ...",
      },
      limit: 3,
      selected: null,

      onlyNumber: {
        numericOnly: true,
        uppercase: true,
      },
      assetData: null,
      searchQuery: ''


    }
  },
  computed: {
    // Compute a property to determine if the input field should be disabled or not
    isFinalConsumer() {
      return this.user.role === 'FINACON' || this.user.role === 'FINACONVI';
    },
  },
  mounted() {
    this.getAsset()
    //this.fetchAssetModels('', 1)
  },
  methods: {
    setSelected() {
      this.modelOptions = []
      this.assetData.modelId = ''
      this.fetchAssetModels(this.assetData.manufacturerId, 1)
    },
    fetchAssetModels(id, page) {
      //Fetch Assets Model
      store.dispatch('app-asset/fetchAssetsModel', {id, page})
          .then(response => {
            response.data.data.map((v, key) => {
              this.modelOptions.push({
                label: v.name,
                value: v.id
              },);
            });

            if (response.data.data.length > 0) this.fetchAssetModels(id, page + 1)
          })
          .catch(error => {

          })
    },

    rulesPlate() {

      if (this.assetData.chassisNumber.length > 5) return "max:20"
      return 'required|max:20'
    },
    rulesChassis() {
      console.log('Chamou func Chassis')

      if (this.assetData.plate.length > 5) return "max:30"
      return "required|max:30"
    },

    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          if (this.assetData.id === "") {
            store.dispatch('app-asset/addAsset', {
              assetData: this.assetData
            })
                .then(response => {
                  this.msgDialog(this.$t('Successful registration'), 'success')
                  router.push({
                    name: 'home'
                  })
                  //this.clearForm();

                })
                .catch(error => {
                  this.msgDialog(this.$t('Failed to save'), 'error')
                  console.log(error);
                  //console.log(error.response.status);
                  //alert(error.response.status)
                })
          } else {
            store.dispatch('app-asset/updateAsset', {
              assetData: this.assetData
            })
                .then(response => {
                  this.msgDialog(this.$t('Successful update'), 'success')
                  //this.clearForm();

                })
                .catch(error => {
                  this.msgDialog(this.$t('FAILURE to update'), 'error')

                  console.log(error.response.status)
                })
          }
        }
      })
    },

    clearForm() {
      this.userData = {
        id: '',
        customerId: "",
        manufacturerId: "",
        modelId: "",
        assetType: 0,
        modelName: "",
        color: "",
        manufacturingYear: 0,
        modelYear: 0,
        plate: "",
        nickname: "",
        chassisNumber: "",

      }
    },

    msgDialog(msg, icon) {
      this.$swal({
        position: 'top-end',
        icon: icon,
        title: msg,
        showConfirmButton: false,
        timer: 1500 * 3,
        customClass: {
          confirmButton: 'btn btn-primary',
        },

        buttonsStyling: false,
      })
    },

    selectHandler(option) {
      this.selected = option.item
      this.filteredOptions = []
      this.searchQuery = option.item.name
      this.assetData.customerId = option.item.id

      console.log(`option selecionado `, this.assetData.customerId)
    },

    onInputChange(text) {
      if (text === '' || text === undefined || text.length < 3) {

        return
      }

      store.dispatch('app-asset/fetchCustomers', {
        q: this.searchQuery,

      })
          .then(response => {
            console.log(`resposta auto `, response.data)

            this.filteredOptions = [{
              data: response.data.data,
            }]

          })
          .catch(error => {

            console.log(error)

          })
    },
    suggestionSelected() {
      console.log('Selecionado', this.selected)
    },
    getAsset() {
      if (router.currentRoute.params.id != undefined) {

        store.dispatch('app-asset/fetchAsset', {
          id: router.currentRoute.params.id
        })
            .then(response => {
              this.assetData = response.data
              if(this.assetData.manufacturerId) this.fetchAssetModels(this.assetData.manufacturerId, 1)


              store.dispatch('app-asset/fetchCustomer', {
                //id: router.currentRoute.params.id
                id: response.data.customerId
              })
                  .then(res => {
                    this.searchQuery = res.data.name;

                  })
                  .catch(error => {

                  })

            })
            .catch(error => {
              if (error.response.status === 404) {
                this.assetData = undefined
              }
            })
      } else {
        this.fetchAssetModels('', 1)
        this.assetData = {
          id: '',
          customerId: null,
          manufacturerId: "",
          modelId: "",
          assetType: 0,
          modelName: "",
          color: "",
          manufacturingYear: 0,
          modelYear: 0,
          plate: "",
          nickname: "",
          chassisNumber: "",
          invoice: "",
          costCenter: "",
          note: ""
        }
      }
    }
  },
  setup() {
    //const assetData = ref(null)
    const manufacturerOptions = ref([])
    const modelOptions = ref([])
    const assetsOptions = ref([])
    //const searchQuery = ref(``)

    const ASSET_APP_STORE_MODULE_NAME = 'app-asset'

    // Register module
    if (!store.hasModule(ASSET_APP_STORE_MODULE_NAME)) store.registerModule(ASSET_APP_STORE_MODULE_NAME, assetStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ASSET_APP_STORE_MODULE_NAME)) store.unregisterModule(ASSET_APP_STORE_MODULE_NAME)
    })

    const user = localStorage.userData != undefined ? JSON.parse(localStorage.userData) : {}

    // if (router.currentRoute.params.id != undefined) {
    //
    //   store.dispatch('app-asset/fetchAsset', {
    //     id: router.currentRoute.params.id
    //   })
    //       .then(response => {
    //         assetData.value = response.data
    //
    //
    //         store.dispatch('app-asset/fetchCustomer', {
    //           //id: router.currentRoute.params.id
    //           id: response.data.customerId
    //         })
    //             .then(res => {
    //               searchQuery.value = res.data.name;
    //
    //
    //             })
    //             .catch(error => {
    //
    //             })
    //
    //       })
    //       .catch(error => {
    //         if (error.response.status === 404) {
    //           assetData.value = undefined
    //         }
    //       })
    // } else {
    //   assetData.value = {
    //     id: '',
    //     customerId: null,
    //     manufacturerId: "",
    //     modelId: "",
    //     assetType: 0,
    //     modelName: "",
    //     color: "",
    //     manufacturingYear: 0,
    //     modelYear: 0,
    //     plate: "",
    //     nickname: "",
    //     chassisNumber: "",
    //     invoice: "",
    //     costCenter: "",
    //     note: ""
    //   }
    // }

    //Fetch Assets Manufacture
    store.dispatch('app-asset/fetchAssetsManufacture')
        .then(response => {
          response.data.data.map(function (v, key) {
            manufacturerOptions.value.push({
              label: v.name,
              value: v.id
            },);
          });

        })
        .catch(error => {

        })

    // //Fetch Assets Model
    // store.dispatch('app-asset/fetchAssetsModel',{id:''})
    //     .then(response => {
    //         response.data.data.map(function (v, key) {
    //             modelOptions.value.push({
    //                 label: v.name,
    //                 value: v.id
    //             }, );
    //         });
    //
    //     })
    //     .catch(error => {
    //
    //     })

    //Fetch Assets Type
    store.dispatch('app-asset/fetchAssetsType')
        .then(response => {
          response.data.data.map(function (v, key) {
            assetsOptions.value.push({
              label: v.name,
              value: v.id
            },);
          });

        })
        .catch(error => {

        })

    /*
    response.data.message.map(function(value, key) {
        list.push(value);
    });*/

    return {
      
      manufacturerOptions,
      modelOptions,
      assetsOptions,
      

      user
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>

<style>

</style>
