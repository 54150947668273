var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('h4',[_vm._v(_vm._s(_vm.$t('Informations')))]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('The fields with (*) are required')))]),_c('span',{staticClass:"font-weight-bold",staticStyle:{"display":"block"}},[_vm._v("** "+_vm._s(_vm.$t('Chassis and or plate must be inserted')))]),_c('hr',{staticClass:"mb-2"}),_c('b-row',[(!_vm.isFinalConsumer)?_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Owner'),"label-for":"customer"}},[_c('vue-autosuggest',{attrs:{"disabled":_vm.isFinalConsumer,"suggestions":_vm.filteredOptions,"input-props":_vm.inputProps},on:{"selected":_vm.selectHandler,"input":_vm.onInputChange},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var suggestion = ref.suggestion;
return [_c('span',{staticClass:"my-suggestion-item"},[_vm._v(_vm._s(suggestion.item.name))])]}}],null,false,3761623635),model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1)],1):_vm._e(),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Manufacturer')+'*',"label-for":"manufacturer"}},[_c('validation-provider',{attrs:{"name":"manufacturer","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"disabled":_vm.isFinalConsumer,"state":errors.length > 0 ? false:null,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.manufacturerOptions,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"manufacturer"},on:{"input":_vm.setSelected},model:{value:(_vm.assetData.manufacturerId),callback:function ($$v) {_vm.$set(_vm.assetData, "manufacturerId", $$v)},expression:"assetData.manufacturerId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Model')+'*',"label-for":"model"}},[_c('validation-provider',{attrs:{"name":"model","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"disabled":_vm.isFinalConsumer,"state":errors.length > 0 ? false:null,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.modelOptions,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"model"},model:{value:(_vm.assetData.modelId),callback:function ($$v) {_vm.$set(_vm.assetData, "modelId", $$v)},expression:"assetData.modelId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Model Name'),"label-for":"model-name"}},[_c('validation-provider',{attrs:{"name":"model-name","rules":"max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":_vm.isFinalConsumer,"id":"model-name"},model:{value:(_vm.assetData.modelName),callback:function ($$v) {_vm.$set(_vm.assetData, "modelName", $$v)},expression:"assetData.modelName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Asset Type')+'*',"label-for":"asset-type"}},[_c('validation-provider',{attrs:{"name":"asset-type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"disabled":_vm.isFinalConsumer,"state":errors.length > 0 ? false:null,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.assetsOptions,"reduce":function (val) { return val.value; },"clearable":false,"input-id":"asset-type"},model:{value:(_vm.assetData.assetType),callback:function ($$v) {_vm.$set(_vm.assetData, "assetType", $$v)},expression:"assetData.assetType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Color'),"label-for":"color"}},[_c('validation-provider',{attrs:{"name":"color","rules":"max:30"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"color"},model:{value:(_vm.assetData.color),callback:function ($$v) {_vm.$set(_vm.assetData, "color", $$v)},expression:"assetData.color"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Manufacturing Year'),"label-for":"manufacturing-year"}},[_c('validation-provider',{attrs:{"name":"manufacturing-year","rules":"max:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"color","type":"number"},model:{value:(_vm.assetData.manufacturingYear),callback:function ($$v) {_vm.$set(_vm.assetData, "manufacturingYear", $$v)},expression:"assetData.manufacturingYear"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Model Year'),"label-for":"model-year"}},[_c('validation-provider',{attrs:{"name":"model-year","rules":"max:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"color","type":"number"},model:{value:(_vm.assetData.modelYear),callback:function ($$v) {_vm.$set(_vm.assetData, "modelYear", $$v)},expression:"assetData.modelYear"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Plate')+'**',"label-for":"plate"}},[_c('validation-provider',{attrs:{"name":"plate","rules":_vm.rulesPlate()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"plate"},model:{value:(_vm.assetData.plate),callback:function ($$v) {_vm.$set(_vm.assetData, "plate", $$v)},expression:"assetData.plate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Nickname'),"label-for":"nickname"}},[_c('validation-provider',{attrs:{"name":"nickname","rules":"max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nickname"},model:{value:(_vm.assetData.nickname),callback:function ($$v) {_vm.$set(_vm.assetData, "nickname", $$v)},expression:"assetData.nickname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Chassis')+'**',"label-for":"chassis"}},[_c('validation-provider',{attrs:{"name":"chassisNumber","rules":_vm.rulesChassis()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"chassis"},model:{value:(_vm.assetData.chassisNumber),callback:function ($$v) {_vm.$set(_vm.assetData, "chassisNumber", $$v)},expression:"assetData.chassisNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Invoice'),"label-for":"invoice"}},[_c('validation-provider',{attrs:{"name":"invoice","rules":"max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"invoice"},model:{value:(_vm.assetData.invoice),callback:function ($$v) {_vm.$set(_vm.assetData, "invoice", $$v)},expression:"assetData.invoice"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Cost Center'),"label-for":"cost-center"}},[_c('validation-provider',{attrs:{"name":"cost-center","rules":"max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"cost-center"},model:{value:(_vm.assetData.costCenter),callback:function ($$v) {_vm.$set(_vm.assetData, "costCenter", $$v)},expression:"assetData.costCenter"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Note'),"label-for":"note"}},[_c('validation-provider',{attrs:{"name":"note","rules":"max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"note","rows":"5"},model:{value:(_vm.assetData.note),callback:function ($$v) {_vm.$set(_vm.assetData, "note", $$v)},expression:"assetData.note"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(!_vm.user.role.endsWith('VI'))?_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")])],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }